@import "./font-definition.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .app-h1-lg {
    @apply font-lutz text-app-h1-lg-mobile md:text-app-h2-mobile lg:text-app-h1-lg xl:text-app-h1-xl;
  }

  .app-h2 {
    @apply font-lutz text-app-h2-mobile md:text-app-h2-md lg:text-app-h2;
  }

  .app-h3 {
    @apply font-lutz text-app-h3-mobile lg:text-app-h3;
  }

  .app-pre-title {
    @apply prose text-pre-title-mobile prose-p:text-pre-title-mobile md:text-pre-title md:prose-p:text-pre-title;
  }

  .app-body-s {
    @apply text-body-s;
  }

  .app-link-sm {
    @apply text-link-sm 2xl:text-link-lg;
  }

  .app-link-m {
    @apply text-link-m;
  }

  .app-link-lg {
    @apply text-link-lg;
  }

  .app-icon-text {
    @apply text-icon-text-sm font-normal lg:text-app-h3-mobile w1920:text-icon-text-lg;
  }

  .body-m {
    @apply font-akkura text-body lg:text-body-m w1920:text-icon-text-lg;
  }

  .wysiwyg {
    @apply prose prose-p:m-0 prose-p:text-body prose-p:font-light prose-a:font-normal prose-a:no-underline prose-ul:text-body;
  }
  .wysiwyg.contact {
    @apply prose-p:app-link-sm lg:prose-p:app-link-m break-all text-black-olive prose-p:mt-3 first-of-type:prose-p:mt-0 prose-strong:text-[16px] prose-strong:font-bold prose-strong:leading-5 lg:prose-strong:text-[18px] lg:prose-strong:leading-6;
  }

  .textGrid-link {
    @apply inline-flex font-bold;
  }
  .textGrid-link img {
    @apply mx-2 my-0 object-contain;
  }
  .textGrid-li {
    @apply relative text-body-s before:absolute before:-left-2 before:top-[11px] before:aspect-square before:w-1 before:rounded-full lg:text-body;
  }

  .carousel-desc-li {
    @apply relative m-0 text-body-s before:absolute before:-left-2 before:top-[11px] before:aspect-square before:w-1 before:rounded-full before:bg-black-olive md:text-body;
  }

  .text-image-grid-item .wysiwyg.textGrid {
    @apply prose-a:textGrid-link prose-li:textGrid-li prose-p:text-body-xs prose-ul:list-none prose-ul:px-5 lg:prose-p:text-body xl:prose-p:text-body-m w1920:max-w-full;
  }
  .text-image-grid-item .wysiwyg.textGrid p:last-of-type {
    @apply mb-0;
  }
  .text-image-grid-item h2 b {
    @apply text-[#ea5452];
  }
  .text-image-grid-item.gainsboro {
    @apply bg-[#f2f2f2];
  }
  .text-image-grid-item.gainsboro h2 {
    @apply text-black-olive;
  }
  .text-image-grid-item.gainsboro .wysiwyg.textGrid {
    @apply prose-li:before:bg-cadet-blue;
  }

  .text-image-grid-item.platinum {
    /* @apply bg-platinum; */
    @apply bg-white;
  }
  .text-image-grid-item.platinum h2 {
    @apply text-[#1c1c1e];
  }
  .text-image-grid-item.platinum .wysiwyg.textGrid {
    @apply prose-li:before:bg-philippine-gold;
  }

  .text-image-grid-item.transparent {
    @apply bg-white;
  }
  .text-image-grid-item.transparent h2 {
    @apply text-philippine-gold;
  }
  .text-image-grid-item.transparent .wysiwyg.textGrid {
    @apply prose-li:before:bg-philippine-gold;
  }

  .wysiwyg.textGrid {
    @apply prose-p:mb-4 last-of-type:prose-p:mb-10 prose-ul:mb-8 lg:prose-p:mb-4 w1920:prose-p:!text-body-xxl w1920:prose-li:!text-body-xxl;
  }

  .carousel-grid .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    @apply absolute bottom-[14px] left-auto right-5 flex w-auto md:bottom-8 md:right-8;
  }

  .carousel-grid .swiper-pagination .swiper-pagination-bullet {
    @apply !m-[6px] h-4 w-4 rounded-sm border border-white bg-transparent p-1 opacity-100;
  }
  .carousel-grid .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    @apply bg-white;
  }

  .carousel .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    /* @apply bottom-0 lg:text-center smh:bottom-6 mdh:bottom-16; */
    @apply bottom-0 lg:text-center;
  }

  .carousel .swiper-pagination .swiper-pagination-bullet {
    @apply h-4 w-4 rounded-sm border border-black-olive bg-transparent opacity-100 hover:bg-black-olive hover:bg-opacity-10;
  }
  .carousel .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    @apply bg-black-olive;
  }

  .animated-page,
  .carousel-full-screen {
    min-height: 100vh;
    min-height: stretch;
    min-height: -webkit-stretch;
    min-height: -webkit-stretch;
  }
  .carousel-full-screen
    .carousel
    .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    @apply opacity-0;
  }

  .carousel-btn {
    @apply app-hover flex aspect-square w-12 items-center backdrop-blur hover:scale-105 active:scale-95 md:backdrop-blur-0;
  }

  .tab-label {
    @apply text-tab font-bold uppercase tracking-widest outline-none w1920:text-link-lg;
  }
  /* Menu Grid */
  .grid-block-1 {
    @apply col-span-2 row-span-1 border-b border-platinum lg:col-span-1 lg:row-span-3 lg:border-r;
  }

  .grid-block-2,
  .grid-block-3 {
    @apply col-span-1 row-span-1 border-b border-platinum lg:col-span-2 lg:row-span-2;
  }

  .grid-block-2 {
    @apply border-r border-platinum lg:border-r-0;
  }

  .grid-block-4 {
    @apply col-span-2 row-span-1 border-b border-platinum lg:col-span-1 lg:row-span-3 lg:border-b-0 lg:border-r;
  }

  .grid-block-5 {
    @apply border-r border-platinum;
  }

  .grid-block-5,
  .grid-block-6 {
    @apply row-span-1 lg:row-span-2;
  }

  .grid-block-1 .pattern,
  .grid-block-4 .pattern {
    @apply right-0 w-full;
  }

  .scrollBtn {
    @apply absolute z-30 rounded border border-white px-4 py-[11px] transition-all duration-300 ease-in-out hover:bg-white;
  }

  .down-arrow {
    @apply fill-white;
  }

  .scrollBtn:hover .down-arrow {
    @apply fill-black-olive;
  }

  /* Select 2nd, 4th, 6th, ... element */
  .icon-grid-item:nth-child(2n) > div {
    @apply border-r-0 lg:border-r;
  }

  /* Select 3rd, 6th, 9th, ... element */
  .icon-grid-item:nth-child(3n) > div {
    @apply lg:border-r-0;
  }

  /* Select the first 3 elements */
  .icon-grid-item:nth-child(-n + 3) {
    @apply lg:pt-0;
  }
  /* Select the last 3 elements */
  .icon-grid-item:nth-last-child(-n + 3) {
    @apply lg:border-b-0 lg:pb-0;
  }

  .icon-grid-item:last-of-type {
    @apply border-b-0 pb-0 md:border-b-0;
  }

  .btn-locale-switcher {
    @apply relative text-white before:absolute before:-bottom-2 before:left-0 before:w-full before:scale-x-0 before:border before:border-white before:transition-all hover:text-white/80;
  }
  .btn-locale-switcher.active {
    @apply before:scale-x-100;
  }
}

@layer utilities {
  .app-hover {
    @apply transition duration-300 ease-in-out;
  }

  .nav-item {
    @apply after:app-hover after:absolute after:-bottom-[6px] after:left-0 after:inline-block after:w-full after:border-t after:border-white after:opacity-0 after:content-[''] hover:after:opacity-100;
  }
  .nav-item-active {
    @apply after:opacity-100;
  }

  .nav-item-light {
    @apply after:app-hover after:absolute after:-bottom-[6px] after:left-0 after:inline-block after:w-full after:border-t after:border-[#1c1c1e] after:opacity-0 after:content-[''] hover:after:opacity-100;
  }

  .landing-vimeo iframe {
    /* height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    /* min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */

    @apply absolute left-1/2 top-1/2 h-[56.25vw] min-h-screen w-screen min-w-[177.77vh]  -translate-x-1/2 -translate-y-1/2;
  }

  .video-player iframe {
    @apply h-full w-full;
  }

  /* Remove background color for autofill input values */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  .form-input {
    @apply appearance-none border-black-shadow bg-transparent pb-4 text-form-item focus:border-philippine-gold focus:outline-none w1920:pb-6;
  }
  .form-label {
    @apply text-form-item-sm font-normal text-black-shadow;
  }
  .form-animate-label {
    @apply absolute -top-5 left-0 transition-all peer-placeholder-shown:top-1 peer-placeholder-shown:text-form-item w1920:peer-placeholder-shown:text-pre-title;
  }
  .form-checkbox {
    @apply relative h-5 w-5 appearance-none rounded-sm border border-black-olive before:absolute before:left-[6px] before:h-[14px] before:w-[7px] before:rotate-45 before:border-b-2 before:border-r-2 before:border-transparent before:transition checked:before:border-philippine-gold focus:ring-0 focus:ring-offset-0;
  }

  .hyphens-auto {
    hyphens: auto;
  }

  .tab-group-panels > div {
    @apply h-full;
  }

  .section-max-w {
    @apply max-w-[1440px];
  }

  .img-caption {
    text-shadow: 0px 2px 15px #000000;
    @apply text-[14px] leading-[14px] text-white;
  }

  .fullscreen-overlay {
    @apply tab-label absolute inset-0 z-10 flex h-full w-full flex-col items-center justify-center rounded bg-black bg-opacity-40 text-center font-normal uppercase text-white opacity-0 transition-opacity ease-in-out lg:group-hover:opacity-100;
  }

  .tab-list-wrapper {
    @apply mt-8 flex w-screen items-center justify-center space-x-8 text-center uppercase text-black-shadow;
  }
  .tab-list-wrapper.bottom {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    @apply fixed bottom-0 z-40 m-0 snap-x snap-mandatory justify-between overflow-x-auto bg-white p-5 sm:justify-center;
  }

  .carousel-tab .carousel-image {
    @apply aspect-h-[462] aspect-w-[528];
  }

  .text-shadow {
    text-shadow: #1c1c1e 1px 0 10px;
  }
}

html {
  height: stretch;
  height: -webkit-stretch;
  font-variant-ligatures: none;
}

body {
  @apply overscroll-none bg-white font-akkura text-body font-light text-[#1c1c1e];
}
