@font-face {
  font-family: "Lutz";
  src: url("fonts/Lutz-Heading/Lutz-Headline.eot");
  src: url("fonts/Lutz-Heading/Lutz-Headline.eot?#iefix") format("embedded-opentype"),
    url("fonts/Lutz-Heading/Lutz-Headline.svg#Lutz-Headline") format("svg"),
    url("fonts/Lutz-Heading/Lutz-Headline.ttf") format("truetype"),
    url("fonts/Lutz-Heading/Lutz-Headline.woff") format("woff"),
    url("fonts/Lutz-Heading/Lutz-Headline.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Akkura";
  src: url("fonts/AkkuratStd/regular/AkkuratStd.eot");
  src: url("fonts/AkkuratStd/regular/AkkuratStd.eot?#iefix") format("embedded-opentype"),
    url("fonts/AkkuratStd/regular/AkkuratStd.svg#AkkuratStd") format("svg"),
    url("fonts/AkkuratStd/regular/AkkuratStd.ttf") format("truetype"),
    url("fonts/AkkuratStd/regular/AkkuratStd.woff") format("woff"),
    url("fonts/AkkuratStd/regular/AkkuratStd.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Akkura";
  src: url("fonts/AkkuratStd/light/AkkuratStd-Light.eot");
  src: url("fonts/AkkuratStd/light/AkkuratStd-Light.eot?#iefix") format("embedded-opentype"),
    url("fonts/AkkuratStd/light/AkkuratStd-Light.svg#AkkuratStd-Light") format("svg"),
    url("fonts/AkkuratStd/light/AkkuratStd-Light.ttf") format("truetype"),
    url("fonts/AkkuratStd/light/AkkuratStd-Light.woff") format("woff"),
    url("fonts/AkkuratStd/light/AkkuratStd-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Akkura";
  src: url("fonts/AkkuratStd/bold/AkkuratStd-Bold.eot");
  src: url("fonts/AkkuratStd/bold/AkkuratStd-Bold.eot?#iefix") format("embedded-opentype"),
    url("fonts/AkkuratStd/bold/AkkuratStd-Bold.svg#AkkuratStd-Bold") format("svg"),
    url("fonts/AkkuratStd/bold/AkkuratStd-Bold.ttf") format("truetype"),
    url("fonts/AkkuratStd/bold/AkkuratStd-Bold.woff") format("woff"),
    url("fonts/AkkuratStd/bold/AkkuratStd-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
